import React from 'react';
import AxiosInstance from './../axiosConfig.js'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            result: '',
            authToken: '',
            isLoading: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        AxiosInstance
            .get('/Auth')
            .then(res => {
                this.setState({
                    authToken: res.data
                });
            })
            .catch(error => {
                //console.log(error);
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        this.setState({
            isLoading: true
        })
        AxiosInstance
            .post('/Email', {
                firstLastName: this.state.name,
                email: this.state.email,
                message: this.state.message
            }, {headers: {
                'token': this.state.authToken
            }})
            .then(res => {
                this.setState({
                    result: "Thank you for reaching out. I will do my best to reply as soon as possible.",
                    name: '',
                    message: '',
                    email: '',
                    isLoading: false
                })
            })
            .catch(error => {
                console.error(error)
                this.setState({
                    result: "Unfortunately, there was an error while sending the message. Please try again later.",
                    isLoading: false
                })
            })

        event.preventDefault();
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <form onSubmit={this.handleSubmit} className="send-form-style" method="POST">
                    <div className="row"><div id="result">{this.state.result}</div></div>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="text" name="name" value={this.state.name} onChange={this.handleInputChange} id="name" required placeholder="Your Name*" />
                        </div>
                        <div className="col-sm-6">
                            <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} id="email" required placeholder="Your Email*" />
                        </div>
                        <div className="col-sm-12">
                            <textarea id="message" name="message" required placeholder="Your Message*" value={this.state.message} onChange={this.handleInputChange}></textarea>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="site-btn transition-ease" data-wow-delay="0.2s"
                                id="send-form">Send Message</button>
                        </div>
                    </div>
                </form>
            );
        } else {
            return (
                <div>
                    <div className="row p-5">
                        <div className="text-center mt-30">
                        <Loader
                            type="Oval"
                            color="#ff9800"
                            height={50}
                            width={50}
                            timeout={-1}
                        />
                        </div>
                        <div className="text-center p-3">Your message is being sent...</div>
                    </div>
                </div>
            );
        }
    }
}

